/**
 *
 * リダイレクト用画面
 *
 */
import * as React from 'react';

interface Props {}

export function RedirectPage(props: Props) {
  React.useEffect(() => {
    window.location.replace('/login');
  }, []);

  return <></>;
}
